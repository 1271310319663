<template>
    <client-page class="main--white">
        <v-container>
            <div class="pt-80 pb-60">
                <v-img max-width="130" class="ma-auto" src="/images/logo.svg"></v-img>
            </div>
            <div>
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="username" v-bind="attrs_input" outlined placeholder="아이디" type="email" autocomplete="username" hide-details />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="password" v-bind="attrs_input" outlined placeholder="비밀번호" type="password" autocomplete="current-password" hide-details @keydown.enter="login" />
                    </v-col>
                </v-row>
            </div>
            <div class="pt-14">
                <v-checkbox v-model="remember" color="primary" label="자동로그인" hide-details />
            </div>
            <div class="pt-24">
                <v-btn block color="primary" class="v-size--xx-large" @click="login">로그인</v-btn>
            </div>
            <!-- <div class="pt-20">
                <ul class="login-btn-list">
                    <li><v-btn x-small text to="/login/find-id" class="font-size-16">아이디 찾기</v-btn></li>
                    <li><v-btn x-small text to="/login/find-password" class="font-size-16">비밀번호 찾기</v-btn></li>
                    <li><v-btn x-small text to="/join" class="font-size-16">회원가입</v-btn></li>
                </ul>
            </div> -->
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "../templates/ClientPage.vue";
import AppleLogin from "@/components/plugins/apple/apple-login.vue";
import KakaoLogin from "@/components/plugins/kakao/kakao-login.vue";

import CryptoAES from "@/plugins/crypto-aes";
import { mapMutations } from "vuex";
import { attrs_input } from "@/assets/variables";

export default {
    components: {
        ClientPage,
        AppleLogin,
        KakaoLogin,
    },
    data() {
        return {
            attrs_input,
            username: this.$cookies.get("username") || "",
            password: CryptoAES.decrypt(this.$cookies.get("password") || ""),

            remember: false,
        };
    },
    methods: {
        ...mapMutations("ui", {
            setShowsHeader: "header/setShows",
            setShowsNavigation: "setShowsNavigation",
        }),
        async login() {
            try {
                await this.$store.dispatch("login", { username: this.username, password: this.password, remember: this.remember });

                if (this.remember) {
                    this.$cookies.set("username", this.username);
                    this.$cookies.set("password", CryptoAES.encrypt(this.password));
                }

                this.$router.push("/");
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
    },
    mounted() {
        this.setShowsHeader(false);
        this.setShowsNavigation(false);
    },
    destroyed() {
        this.setShowsHeader(true);
        this.setShowsNavigation(true);
    },
};
</script>
