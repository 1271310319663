var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.join ? _c('div', _vm._b({
    attrs: {
      "id": "appleid-signin",
      "data-type": "sign-up"
    }
  }, 'div', _vm.buttonAttrs, false)) : _c('div', _vm._b({
    attrs: {
      "id": "appleid-signin",
      "data-type": "sign-in"
    }
  }, 'div', _vm.buttonAttrs, false));
}
var staticRenderFns = []

export { render, staticRenderFns }