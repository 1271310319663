var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.join ? _c('v-btn', _vm._b({
    staticClass: "black--text font-size-18",
    attrs: {
      "large": "",
      "rounded": "",
      "width": "100%",
      "max-width": "375",
      "height": "48",
      "color": "#FDDC35"
    }
  }, 'v-btn', {
    href: _vm.href
  }, false), [_c('v-img', {
    staticClass: "mr-8",
    attrs: {
      "contain": "",
      "max-width": "18px",
      "src": "/images/icon/icon-k@2x.png"
    }
  }), _vm._v(" KAKAO로 등록 ")], 1) : _c('v-btn', _vm._b({
    staticClass: "black--text font-size-18",
    attrs: {
      "large": "",
      "rounded": "",
      "width": "100%",
      "max-width": "375",
      "height": "48",
      "color": "#FDDC35"
    }
  }, 'v-btn', {
    href: _vm.href
  }, false), [_c('v-img', {
    staticClass: "mr-8",
    attrs: {
      "contain": "",
      "max-width": "18px",
      "src": "/images/icon/icon-k@2x.png"
    }
  }), _vm._v(" KAKAO로 로그인 ")], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }