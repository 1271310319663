var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "main--white"
  }, [_c('v-container', [_c('div', {
    staticClass: "pt-80 pb-60"
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "max-width": "130",
      "src": "/images/logo.svg"
    }
  })], 1), _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "placeholder": "아이디",
      "type": "email",
      "autocomplete": "username",
      "hide-details": ""
    },
    model: {
      value: _vm.username,
      callback: function callback($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "placeholder": "비밀번호",
      "type": "password",
      "autocomplete": "current-password",
      "hide-details": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.login.apply(null, arguments);
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1)], 1), _c('div', {
    staticClass: "pt-14"
  }, [_c('v-checkbox', {
    attrs: {
      "color": "primary",
      "label": "자동로그인",
      "hide-details": ""
    },
    model: {
      value: _vm.remember,
      callback: function callback($$v) {
        _vm.remember = $$v;
      },
      expression: "remember"
    }
  })], 1), _c('div', {
    staticClass: "pt-24"
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v("로그인")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }