<template>
    <v-btn v-if="join" v-bind="{ href }" large rounded width="100%" max-width="375" height="48" color="#FDDC35" class="black--text font-size-18">
        <v-img contain max-width="18px" class="mr-8" src="/images/icon/icon-k@2x.png"></v-img>
        KAKAO로 등록
    </v-btn>
    <v-btn v-else v-bind="{ href }" large rounded width="100%" max-width="375" height="48" color="#FDDC35" class="black--text font-size-18">
        <v-img contain max-width="18px" class="mr-8" src="/images/icon/icon-k@2x.png"></v-img>
        KAKAO로 로그인
    </v-btn>
    <!-- <v-btn text height="100%" class="v-btn--effect-none pa-0" v-bind="{ href }">
        <div>
            <v-avatar size="48" style="background-color: #fddc35">
                <v-img contain max-width="18px" src="/images/icon/icon-k@2x.png"></v-img>
            </v-avatar>
            <div v-if="!hidesLabel" class="font-size-12 mt-8">{{ label }}</div>
        </div>
    </v-btn> -->
</template>

<script>
export default {
    props: {
        client_id: { type: String, default: "" },
        join: { type: Boolean, default: false },
        hidesLabel: { type: Boolean, default: false },
        // label: { type: String, default: "카카오톡 로그인" },
    },
    computed: {
        redirect_uri: function () {
            return `${document.location.protocol}//${document.location.hostname}/oauth/kakao`;
        },
        href() {
            return `https://kauth.kakao.com/oauth/authorize?client_id=${this.client_id}&redirect_uri=${this.redirect_uri}&response_type=code`;
        },
    },
};
</script>